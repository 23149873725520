<template>
  <div id='container'></div>
  <div style="margin:15px;">
    <van-row gutter="20">
      <van-col span="12">
        <van-button type="primary" block  @click="onSave(true)">保存位置</van-button>
      </van-col>
      <van-col span="12">
        <van-button type="default" block  @click="onSave(false)">清除位置</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch } from 'vue'
import { Toast } from 'vant'
import { gdMap } from '@/util/util'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      store: {},
      longitude: 0,
      latitude: 0
    })
    var map = reactive({})
    const onSave = (type) => {
      post('/store.update', {
        ...state.store,
        physical: type,
        longitude: type ? state.longitude : 0,
        latitude: type ? state.latitude : 0
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
        } else {
          Toast(res.msg)
        }
      })
    }
    const getMap = () => {
      gdMap().then(AMap => {
        // 加载地图
        map = new AMap.Map('container', {
          zoom: 16
        })
        AMap.plugin('AMap.ToolBar', () => {
          var toolbar = new AMap.ToolBar()
          map.addControl(toolbar)
        })
        if (state.longitude === 0) {
          // 定位
          AMap.plugin('AMap.Geolocation', () => {
            var geolocation = new AMap.Geolocation({
              enableHighAccuracy: true,
              timeout: 10000,
              buttonOffset: new AMap.Pixel(0, 0),
              zoomToAccuracy: true,
              buttonPosition: 'RB'
            })
            geolocation.getCurrentPosition((status, result) => {
              if (status === 'complete') {
                state.location = result.position
              } else {
                state.location = [104.416612, 31.153143]
              }
              map.setCenter(state.location)
            })
          })
        } else {
          map.setCenter([state.longitude, state.latitude])
        }
        // 拖拽
        var positionPicker = new window.AMapUI.PositionPicker({
          mode: 'dragMap',
          map: map
        })
        positionPicker.on('success', function (positionResult) {
          state.longitude = positionResult.position.lng
          state.latitude = positionResult.position.lat
        })
        positionPicker.on('fail', function (positionResult) {
        })
        positionPicker.start()
      })
    }
    watch(() => state.store, (to, previous) => {
    }, { deep: true })
    const init = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        state.store = Object.assign({}, res.data)
        state.longitude = state.store.longitude
        state.latitude = state.store.latitude
        getMap()
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSave
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 500px;
}
</style>
